import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
<path d="M1913 6019 l-60 -102 60 -109 59 -110 -27 -52 c-16 -28 -34 -58 -41
-67 -8 -8 -14 -18 -14 -22 0 -3 -40 -72 -88 -154 -275 -463 -273 -458 -279
-532 -6 -59 15 -131 37 -131 6 0 9 -4 6 -8 -2 -4 11 -18 31 -32 28 -19 49 -24
111 -26 54 -2 72 0 59 7 -21 12 -13 29 12 29 22 0 23 32 3 40 -10 4 -7 11 9
25 12 11 19 15 16 8 -5 -7 0 -9 13 -6 25 7 26 20 3 26 -10 3 -2 6 17 7 29 1
32 3 15 10 -17 8 -16 9 10 11 22 2 16 4 -20 9 -48 7 -47 7 25 9 54 1 71 4 60
11 -8 6 -53 10 -100 9 -96 -1 -96 -2 -49 74 16 26 30 53 32 60 1 6 7 17 13 24
6 6 42 65 79 130 37 65 78 135 92 156 13 21 21 43 17 49 -4 6 -3 8 3 5 5 -3
24 19 43 49 l33 55 21 -33 c11 -18 24 -36 29 -39 5 -3 9 -10 9 -15 1 -21 11
-33 21 -27 6 3 7 1 3 -5 -9 -15 38 -102 50 -94 5 4 9 23 8 42 -2 54 -3 55 -18
35 -15 -21 -10 -49 7 -38 6 4 7 1 2 -7 -6 -10 -11 -9 -22 5 -9 13 -10 22 -3
29 14 14 13 29 -2 20 -6 -4 -8 -3 -4 4 6 10 36 9 55 -3 5 -3 11 7 13 22 3 23
8 27 33 27 17 -1 29 -6 28 -12 -2 -7 4 -9 14 -5 11 4 14 3 9 -5 -10 -17 -1
-15 14 4 8 8 9 14 4 13 -22 -4 -32 2 -37 22 -3 12 -10 18 -16 14 -6 -3 -11 1
-11 9 0 20 19 21 26 1 4 -8 14 -15 23 -15 14 1 13 3 -3 15 -11 8 -17 15 -13
15 4 0 0 14 -9 31 -13 25 -18 29 -26 18 -7 -10 -8 -9 -4 7 4 14 -8 46 -34 94
-22 40 -40 76 -40 81 0 8 16 37 85 157 17 29 35 52 42 52 7 0 28 -29 49 -65
20 -36 43 -73 52 -82 9 -9 17 -21 17 -27 1 -25 46 -96 57 -90 8 5 9 2 5 -9 -3
-10 -2 -17 3 -17 6 0 10 6 10 13 -1 19 -26 48 -35 39 -5 -4 -5 -1 -1 6 9 14
46 -2 46 -20 0 -6 3 -8 7 -5 3 4 -2 17 -13 29 -18 20 -18 21 -1 15 11 -4 16
-3 12 3 -7 11 15 58 30 62 6 2 9 9 9 15 -1 7 2 10 7 7 14 -8 11 4 -7 31 -9 13
-14 30 -11 37 4 9 6 8 6 -3 1 -9 8 -22 16 -29 13 -11 14 -10 9 4 -3 9 -1 16 5
16 6 0 11 -9 11 -20 0 -11 5 -20 11 -20 5 0 8 3 6 8 -2 4 -11 24 -21 45 -9 20
-21 37 -26 37 -6 0 -9 3 -9 8 1 4 1 14 0 22 -1 13 -3 13 -12 0 -9 -13 -10 -12
-5 3 7 21 -13 69 -26 61 -5 -3 -8 -2 -7 3 6 34 -49 98 -106 124 -71 33 -174
19 -229 -29 -10 -9 -38 -47 -62 -84 -24 -38 -46 -68 -48 -68 -3 0 -30 47 -61
105 -31 58 -57 105 -59 105 -2 0 -30 -46 -63 -101z m567 -239 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m91 -17 c-12 -20 -14
-14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m-41 -50 c-1 -18 -18 -35 -24
-24 -4 6 -2 11 4 11 6 0 8 5 5 10 -3 6 -1 10 4 10 6 0 11 -3 11 -7z m-230
-247 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-567 -773 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M4780 5699 c0 -84 2 -100 14 -96 37 15 86 18 105 8 21 -12 21 -10 21
89 l0 100 -70 0 -70 0 0 -101z"/>
<path d="M2647 5757 c-3 -8 3 -13 17 -14 11 0 15 1 8 4 -6 3 -10 9 -6 14 3 5
1 9 -4 9 -6 0 -12 -6 -15 -13z"/>
<path d="M3637 5689 c-29 -66 -27 -84 5 -73 14 4 18 3 13 -5 -5 -8 1 -10 18
-6 15 4 28 2 32 -6 6 -8 4 -10 -5 -4 -9 5 -11 4 -6 -4 6 -9 11 -9 22 0 11 9
18 9 29 -1 13 -11 14 -10 10 3 -8 19 13 33 28 18 15 -15 30 -14 21 2 -5 8 -2
8 9 -2 9 -7 17 -10 17 -5 0 4 7 -1 15 -12 12 -15 14 -16 15 -3 0 9 -3 19 -7
23 -4 3 -8 12 -9 19 -1 7 -12 36 -24 65 l-22 52 -68 0 -67 0 -26 -61z"/>
<path d="M7230 5700 c0 -22 32 -44 46 -30 5 5 27 10 49 12 28 2 41 8 43 21 3
15 -5 17 -67 17 -64 0 -71 -2 -71 -20z"/>
<path d="M4900 5580 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M3476 5313 c-21 -49 -44 -101 -51 -118 -45 -100 -63 -142 -67 -155
-3 -8 -7 -19 -9 -25 -3 -6 26 -10 74 -11 76 -1 80 0 76 32 0 4 3 10 8 13 8 6
34 64 47 104 5 15 22 17 174 17 l169 0 28 -62 c16 -35 29 -66 29 -70 2 -18 26
-27 80 -29 46 -2 60 1 68 14 6 11 5 17 -2 17 -6 0 -8 5 -5 10 4 6 3 16 -2 23
-6 7 -35 73 -67 148 -57 136 -57 137 -94 143 -20 3 -54 6 -76 6 -32 0 -37 -3
-31 -17 16 -39 17 -53 4 -54 -35 -1 -204 3 -206 5 -2 2 4 20 12 41 9 20 15 38
13 40 -2 1 -33 5 -69 9 l-66 6 -37 -87z"/>
<path d="M4090 5276 c0 -78 17 -135 52 -173 20 -22 45 -22 32 -1 -5 7 -2 8 5
3 9 -5 10 -10 2 -18 -16 -16 -14 -25 8 -37 14 -7 22 -7 27 2 5 7 3 8 -7 3 -8
-5 -6 1 4 12 12 14 15 23 7 28 -5 3 -8 11 -4 16 9 14 23 1 19 -19 -2 -9 -4
-17 -5 -17 -5 0 -7 -34 -1 -41 9 -13 54 -37 47 -26 -3 6 -3 12 1 15 5 2 2 2
-5 0 -7 -1 -10 1 -7 6 8 13 61 6 58 -8 -4 -13 7 -14 27 -1 9 5 19 5 27 -2 25
-20 26 2 2 23 -22 19 -22 21 -3 15 11 -3 28 0 40 9 10 8 26 14 34 14 10 0 11
-2 3 -6 -18 -7 -16 -23 2 -23 14 0 19 11 16 38 -2 15 4 15 28 0 11 -6 16 -19
14 -29 -5 -19 -33 -35 -33 -19 0 5 -7 7 -15 4 -8 -4 -13 -10 -10 -14 17 -28
99 35 84 63 -6 9 -3 19 6 27 13 11 14 10 9 -4 -4 -11 -1 -16 11 -16 9 0 13 -4
10 -10 -4 -6 10 -10 34 -10 36 0 41 3 42 23 1 21 1 21 8 -3 12 -45 21 3 21
116 l0 104 -70 0 -70 0 0 -42 c0 -24 -3 -54 -7 -67 -5 -19 -3 -22 8 -16 8 5
11 4 6 -1 -5 -5 -12 -9 -17 -9 -4 0 -21 -13 -37 -30 -16 -16 -38 -32 -49 -33
-31 -6 -48 -23 -32 -34 10 -7 10 -8 -2 -5 -8 2 -14 9 -13 14 2 10 -50 23 -104
26 -11 1 -19 6 -16 10 3 5 -4 18 -15 30 -29 31 -42 70 -42 126 0 46 -1 49 -31
55 -17 3 -47 6 -65 6 l-34 0 0 -74z m445 -144 c-3 -3 -11 0 -18 7 -9 10 -8 11
6 5 10 -3 15 -9 12 -12z m-25 -12 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z m-163 -56 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z"/>
<path d="M4781 5193 c1 -96 3 -113 12 -90 12 29 26 34 45 15 10 -10 15 -10 21
1 5 8 16 11 24 8 8 -3 19 -1 24 4 7 7 17 4 29 -8 16 -17 17 -17 11 0 -7 20 3
22 22 5 18 -19 3 20 -22 53 -11 15 -22 47 -24 72 -6 51 -15 57 -95 57 l-48 0
1 -117z m139 -3 c-13 -8 -13 -10 1 -10 9 0 23 -8 30 -17 11 -15 11 -16 -1 -5
-20 17 -35 15 -20 -3 7 -8 8 -15 3 -15 -12 0 -34 46 -26 54 4 3 11 6 17 6 6 0
4 -5 -4 -10z m-17 -48 c-7 -18 -16 -7 -19 24 -3 24 -2 25 10 8 8 -10 11 -24 9
-32z"/>
<path d="M5248 5273 c0 -10 0 -29 1 -41 1 -17 -2 -22 -10 -16 -9 5 -10 2 -6
-9 3 -9 3 -18 -1 -20 -4 -2 -1 -2 7 0 8 2 18 -1 21 -7 5 -8 9 -6 13 5 4 9 3
13 -3 10 -5 -3 -8 12 -7 34 1 33 3 36 13 23 7 -9 25 -17 40 -18 16 -1 33 -3
38 -3 4 -1 5 -13 2 -28 -5 -23 -4 -25 5 -10 6 10 9 24 6 30 -3 7 3 23 13 35
l18 22 17 -26 c20 -31 32 -25 14 8 -11 21 -11 22 4 9 10 -7 17 -20 17 -27 0
-21 21 -17 27 6 6 23 21 17 33 -14 7 -18 9 -15 9 16 l1 36 -135 1 c-123 2
-135 0 -137 -16z"/>
<path d="M5390 5252 c0 -7 7 -20 15 -28 19 -20 20 -4 0 22 -9 12 -14 14 -15 6z"/>
<path d="M2128 5151 c-11 -20 -13 -33 -6 -42 8 -11 9 -10 5 3 -7 21 3 31 19
17 9 -8 12 -4 10 16 -5 37 -9 38 -28 6z"/>
<path d="M5316 5158 c3 -4 11 -5 19 -1 21 7 19 12 -4 10 -11 0 -18 -5 -15 -9z"/>
<path d="M2088 5073 c6 -2 12 -9 12 -14 0 -4 -6 -6 -14 -3 -9 3 -20 -4 -27
-18 -15 -28 -21 -70 -8 -62 5 3 7 9 4 14 -3 5 0 20 6 33 9 20 18 25 42 24 20
-1 32 3 34 13 4 13 3 13 -6 0 -9 -12 -11 -12 -17 3 -3 9 -14 17 -23 16 -10 0
-11 -2 -3 -6z"/>
<path d="M4487 5073 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M4301 5021 c-24 -15 31 -37 55 -22 17 10 15 11 -11 9 -20 -2 -29 1
-27 10 2 14 1 14 -17 3z"/>
<path d="M4420 5019 c-13 -9 -13 -10 1 -6 9 3 23 0 30 -6 11 -10 11 -9 0 6 -7
9 -14 17 -15 17 0 0 -8 -5 -16 -11z"/>
<path d="M2009 4943 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M2025 4930 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M1768 4693 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
